import React from 'react';
import { Loader, Table, List, Popup, Message } from 'semantic-ui-react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import { formatCurrency } from '../../Utils/number';
import NumberFormater from '../../Components/NumberFormater/NumberFormater';
import LoadError from '../LoadError';
import TableHeaderCellContent from '../TableHeaderCell/TableHeaderCell';
import { Link, useSearchParams } from 'react-router-dom';
import SortableTable, { SortDirection } from '../SortableTable/SortableTable';
import SortableHeaderCell from '../SortableTable/Components/SortableHeaderCell';
import ContractTenantsModal from './Components/ContractTenantsModal';
import styles from './LeaseTable.module.scss';
import LeasePopup from './Components/LeasePopup';
import { hasPermittedReadRoleInOrganisation } from '../../Utils/permissions';
import ContractModal from './Components/ContractModal/ContractModal';
import { alterSearchParam, contractApi, getSearchParam } from '../../Http/Http';
import { ContractApiContractResourceListRequest, ContractListView } from '../../GeneratedServices';
import { translateObjectType } from '../../Services/BusinessPlanService.types';
import { getContractStatusContentAndColor } from '../../Services/ContractService.types';
import { useTranslation } from 'react-i18next';
import LeaseStatusLabel from './Components/LeaseStatusLabel';
import OverlayLoader from '../OverlayLoader';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import { parseNatural } from '../../Utils/safe-parse';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
  tenantId?: number;
  includePropertyData?: boolean;
  includeTenantData?: boolean;
  reloadNonce?: number;
  leaseFilterParams?: ContractApiContractResourceListRequest;
}

enum Status {
  None,
  Loading,
  LoadError
}

const LeaseTable: React.FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'contracts']);
  const [searchParams, setSearchParams] = useSearchParams();
  const contractIdParam = parseNatural(getSearchParam(searchParams, 'contractId'));

  const { handleHttpErrors, currentUser, currentUserMemberships } = React.useContext(globalStateCTX);

  const { organisationIds } = props.filterValues ?? {};
  const isPermitted =
    organisationIds && organisationIds.length !== 0
      ? organisationIds.some((item) => hasPermittedReadRoleInOrganisation(currentUser, currentUserMemberships, item))
      : hasPermittedReadRoleInOrganisation(currentUser, currentUserMemberships);

  const [sortBy, setSortBy] = React.useState<ContractApiContractResourceListRequest['sortBy']>();

  const [status, setStatus] = React.useState<Status>(Status.Loading);
  const [leases, setLeases] = React.useState<ContractListView>();
  const [shouldReloadLease, reloadLeases] = useNonce();

  const [contractIdForTenantsModal, setContractIdForTenantsModal] = React.useState<number>();
  const [contractToShowInModal, setContractToShowInModal] = React.useState<number | undefined>(contractIdParam);

  React.useEffect(() => {
    const abortController = new AbortController();

    const load = async () => {
      try {
        setStatus(Status.Loading);
        const { data } = await contractApi.contractResourceList(
          {
            propertyId: props.propertyId,
            sortBy,
            ...props.filterValues,
            tenantId: props.tenantId,
            ...props.leaseFilterParams
          },
          {
            signal: abortController.signal
          }
        );

        setLeases(data);

        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    load();

    return () => {
      abortController.abort();
    };
  }, [
    props.propertyId,
    shouldReloadLease,
    props.reloadNonce,
    handleHttpErrors,
    sortBy,
    props.filterValues,
    props.tenantId,
    props.leaseFilterParams
  ]);

  const handleSortChange = (columnKey?: string | undefined, sortDirection?: SortDirection | undefined) => {
    let newSortBy: ContractApiContractResourceListRequest['sortBy'] | undefined = undefined;
    switch (columnKey) {
      case 'propertyName':
        newSortBy = sortDirection === SortDirection.ASC ? 'property_name_asc' : 'property_name_desc';
        break;
      case 'tenantName':
        newSortBy = sortDirection === SortDirection.ASC ? 'tenant_name_asc' : 'tenant_name_desc';
        break;
      case 'startDate':
        newSortBy = sortDirection === SortDirection.ASC ? 'start_date_asc' : 'start_date_desc';
        break;
      case 'terminationDate':
        newSortBy = sortDirection === SortDirection.ASC ? 'status_asc' : 'status_desc';
        break;
      case 'lettableArea':
        newSortBy = sortDirection === SortDirection.ASC ? 'lettable_area_asc' : 'lettable_area_desc';
        break;
      case 'baseRentIndexed':
        newSortBy = sortDirection === SortDirection.ASC ? 'base_rent_indexed_asc' : 'base_rent_indexed_desc';
        break;
      case 'additionsIndexed':
        newSortBy = sortDirection === SortDirection.ASC ? 'additions_indexed_asc' : 'additions_indexed_desc';
        break;
      case 'totalRentIndexed':
        newSortBy = sortDirection === SortDirection.ASC ? 'total_rent_indexed_asc' : 'total_rent_indexed_desc';
        break;
      case 'terminationPeriod':
        newSortBy = sortDirection === SortDirection.ASC ? 'termination_period_asc' : 'termination_period_desc';
        break;
      case 'extensionPeriod':
        newSortBy = sortDirection === SortDirection.ASC ? 'extension_period_asc' : 'extension_period_desc';
        break;
      case 'reductions':
        newSortBy = sortDirection === SortDirection.ASC ? 'reductions_indexed_asc' : 'reductions_indexed_desc';
        break;
      case 'ID':
        newSortBy = sortDirection === SortDirection.ASC ? 'property_object_id_asc' : 'property_object_id_desc';
        break;
      case 'type':
        newSortBy = sortDirection === SortDirection.ASC ? 'unit_object_type_asc' : 'unit_object_type_desc';
    }
    setSortBy(newSortBy);
  };

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return leases && leases.records.length !== 0 ? generalLayout : layoutLoading;
      case Status.None:
        return generalLayout;
      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const handleContractClose = () => {
    if (contractIdParam) {
      setSearchParams((prevValue) => alterSearchParam(prevValue, 'contractId', undefined));
    }

    setContractToShowInModal(undefined);
  };

  const layoutLoading = <Loader inline="centered" active size="large" />;

  const layoutLoadError = <LoadError message={t('contracts:unableToFetchLeases')} retry={reloadLeases} />;

  const layoutEmpty = <Message>{t('contracts:noLeasesFound')}</Message>;

  const generalLayout =
    leases?.records.length !== 0 ? (
      <OverlayLoader loading={status === Status.Loading} top={'10vh'}>
        <div className={styles.tableWrapper}>
          <SortableTable handleSort={handleSortChange} additionalTableProps={{ singleLine: true, selectable: true }}>
            <Table.Header>
              <Table.Row>
                <SortableHeaderCell name="ID">
                  <TableHeaderCellContent header={'ID'} />
                </SortableHeaderCell>

                {props.includePropertyData && (
                  <SortableHeaderCell name="propertyName">
                    <TableHeaderCellContent header={t('common:property')} />
                  </SortableHeaderCell>
                )}
                {props.includeTenantData && (
                  <SortableHeaderCell name="tenantName">
                    <TableHeaderCellContent header={t('common:tenant')} />
                  </SortableHeaderCell>
                )}

                <SortableHeaderCell name="type">
                  <TableHeaderCellContent header={t('common:type')} />
                </SortableHeaderCell>

                <SortableHeaderCell name="startDate">
                  <TableHeaderCellContent header={t('common:start')} />
                </SortableHeaderCell>

                <SortableHeaderCell name="terminationDate">
                  <TableHeaderCellContent header={t('common:status')} />
                </SortableHeaderCell>

                <SortableHeaderCell name="terminationPeriod">
                  <TableHeaderCellContent header={t('contracts:noticePeriod')} extra={t('common:monthsShort')} />
                </SortableHeaderCell>

                <SortableHeaderCell name="extensionPeriod">
                  <TableHeaderCellContent header={t('contracts:extensionPeriod')} extra={t('common:monthsShort')} />
                </SortableHeaderCell>

                <SortableHeaderCell name="lettableArea">
                  <TableHeaderCellContent header={t('contracts:leaseArea')} extra={t('common:sqm')} />
                </SortableHeaderCell>

                <SortableHeaderCell name="baseRentIndexed">
                  <TableHeaderCellContent header={t('common:baseRent')} extra="SEK" />
                </SortableHeaderCell>

                <SortableHeaderCell name="additionsIndexed">
                  <TableHeaderCellContent header={t('contracts:additions')} extra="SEK" />
                </SortableHeaderCell>

                <SortableHeaderCell name="reductions">
                  <TableHeaderCellContent header={t('contracts:reductions')} extra="SEK" />
                </SortableHeaderCell>

                <SortableHeaderCell name="totalRentIndexed">
                  <TableHeaderCellContent header={t('common:totalRent')} extra="SEK" />
                </SortableHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {leases?.records.map((item) => {
                return (
                  <Popup
                    key={item.id}
                    positionFixed
                    hoverable
                    disabled={!isPermitted}
                    position="top right"
                    content={
                      <LeasePopup
                        businessPlanLease={item.businessPlanLease}
                        previousContractTotalRentIndexed={item.previousContractTotalRentIndexed}
                        currentContractTotalRentIndexed={item.totalRentIndexed}
                      />
                    }
                    trigger={
                      <Table.Row onClick={() => setContractToShowInModal(item.id)}>
                        <Table.Cell>
                          {item.strifastPropertyId}-{item.strifastObjectId}
                        </Table.Cell>
                        {props.includePropertyData && (
                          <Table.Cell>
                            <Link to={`/properties/${item.propertyId}`}>{item.property.name}</Link>
                          </Table.Cell>
                        )}
                        {props.includeTenantData && (
                          <Table.Cell>
                            {item.tenant ? (
                              item.tenants <= 1 ? (
                                <Link to={`/tenants/${item.tenantId}`}>{item.tenant.name}</Link>
                              ) : (
                                <List.Item
                                  as="a"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setContractIdForTenantsModal(item.id);
                                  }}
                                >
                                  {`${item.tenant.name} +${item.tenants - 1}`}
                                </List.Item>
                              )
                            ) : (
                              t('contracts:vacant')
                            )}
                          </Table.Cell>
                        )}
                        <Table.Cell>{translateObjectType(item.unitObjectType)}</Table.Cell>
                        <Table.Cell>{item.moveInDate}</Table.Cell>
                        <Table.Cell>
                          <LeaseStatusLabel {...getContractStatusContentAndColor(item)} />
                        </Table.Cell>

                        <Table.Cell>{item.terminationPeriod}</Table.Cell>
                        <Table.Cell>{item.extensionPeriod ?? '-'}</Table.Cell>

                        <Table.Cell>
                          <NumberFormater value={item.indoorArea} />
                        </Table.Cell>
                        <Table.Cell>
                          {item.baseRentIndexed !== null ? (
                            <NumberFormater
                              value={formatCurrency(item.baseRentIndexed, { forceCurrency: 'SEK' }).value}
                              additionalProps={{
                                decimalScale: 0
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {item.additionalsIndexed !== null ? (
                            <NumberFormater
                              value={
                                formatCurrency(item.additionalsIndexed, {
                                  forceCurrency: 'SEK'
                                }).value
                              }
                              additionalProps={{
                                decimalScale: 0,
                                allowNegative: true
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </Table.Cell>

                        <Table.Cell>
                          {item.reductionsIndexed !== null ? (
                            <NumberFormater
                              value={formatCurrency(item.reductionsIndexed, { forceCurrency: 'SEK' }).value}
                              additionalProps={{
                                decimalScale: 0,
                                allowNegative: true
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </Table.Cell>

                        <Table.Cell>
                          {item.totalRentIndexed !== null ? (
                            <NumberFormater
                              value={formatCurrency(item.totalRentIndexed, { forceCurrency: 'SEK' }).value}
                              additionalProps={{
                                decimalScale: 0,
                                allowNegative: true
                              }}
                            />
                          ) : (
                            '-'
                          )}
                        </Table.Cell>
                      </Table.Row>
                    }
                  />
                );
              })}
            </Table.Body>
          </SortableTable>
        </div>
      </OverlayLoader>
    ) : (
      layoutEmpty
    );

  return (
    <>
      {getLayout()}

      {contractIdForTenantsModal && (
        <ContractTenantsModal
          onClose={() => setContractIdForTenantsModal(undefined)}
          contractId={contractIdForTenantsModal}
        />
      )}

      {contractToShowInModal && <ContractModal contractId={contractToShowInModal} close={handleContractClose} />}
    </>
  );
};

export default LeaseTable;
