import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMonth, getYear } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Header, Icon, Label, Loader } from 'semantic-ui-react';
import { BAM_API_BASE_URL } from '../../config';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import { EnergyReportListView } from '../../GeneratedServices';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { utilityApi } from '../../Http/Http';
import { UtilityMetricsType, UtilityType } from '../../Services/UtilityService.types';
import colors from '../../Utils/Colors/Colors.module.scss';
import { useNonce } from '../../Utils/hooks';
import LoadError from '../LoadError';
import OverlayLoader from '../OverlayLoader';
import EnergyReportBarGraph from './Components/EnergyReportBarGraph';
import EnergyReportFilters, {
  concatUtilityCombinations,
  translateUtilityCombinations
} from './Components/EnergyReportFilters';
import EnergyReportTable from './Components/EnergyReportTable';
import styles from './EnergyReport.module.scss';

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

enum Status {
  None,
  Loading,
  LoadError
}

const getSelectedPeriodsBasedOnGranularity = (
  prevValue: string[],
  granularity: 'QUARTERLY' | 'YEARLY' | 'MONTHLY',
  allPeriods: string[]
) => {
  const currentYear = getYear(new Date());
  const currentMonth = getMonth(new Date());
  const quarterlyPeriodsInCurrentYear = allPeriods.filter((item) => item.includes(currentYear.toString()));
  const periodsInCurrentYear = allPeriods.filter((item) => currentYear.toString().includes(item));
  const currentMonthPeriod = allPeriods.filter(
    (item) => item.includes(currentYear.toString()) && item.includes(currentMonth.toString())
  );

  let shouldChangeSelection = false;

  prevValue.forEach((item) => {
    if (!allPeriods.includes(item)) {
      shouldChangeSelection = true;
    }
  });
  if (shouldChangeSelection || prevValue.length === 0)
    return granularity === 'QUARTERLY'
      ? quarterlyPeriodsInCurrentYear
      : granularity === 'YEARLY'
      ? periodsInCurrentYear
      : currentMonthPeriod;
  else return prevValue;
};

const EnergyReport: React.FC<Props> = (props) => {
  const { t } = useTranslation(['energyReport', 'common']);

  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const abortControllerRef = React.useRef<AbortController>();

  const [energyReport, setEnergyReport] = React.useState<EnergyReportListView>();
  const [status, setStatus] = React.useState<Status>(Status.Loading);
  const [shouldReload, reload] = useNonce();

  const [granularity, setGranularity] = React.useState<'MONTHLY' | 'QUARTERLY' | 'YEARLY'>('QUARTERLY');
  const [filters, setFilters] = React.useState<{ utilityType: UtilityType; utilityMetricType: UtilityMetricsType }>({
    utilityType: UtilityType.ELECTRICITY,
    utilityMetricType: UtilityMetricsType.ENERGY_KWH
  });

  const [selectedPeriods, setSelectedPeriods] = React.useState<string[]>([]);

  React.useEffect(() => {
    abortControllerRef.current = new AbortController();
    const loadEnergyData = async () => {
      try {
        setStatus(Status.Loading);
        const result = await utilityApi.energyReportResourceList(
          {
            // Set a smaller limit to avoid issue where endpoint returns incomplete data.
            limit: 6000,
            granularity,
            ...props.filterValues,
            propertyId: props.propertyId,
            utilityType: filters.utilityType,
            utilityMetricType: filters.utilityMetricType
          },
          {
            signal: abortControllerRef.current?.signal
          }
        );

        const allPeriods = result.data.periods;
        setSelectedPeriods((prevValue) => getSelectedPeriodsBasedOnGranularity(prevValue, granularity, allPeriods));
        setEnergyReport(result.data);

        setTimeout(() => setStatus(Status.None), 1000);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    loadEnergyData();

    return () => abortControllerRef.current?.abort();
  }, [handleHttpErrors, props.filterValues, granularity, props.propertyId, shouldReload, filters]);

  const exportEnergyReport = async () => {
    try {
      var url = await utilityApi.energyReportResourceExportExcelUrl({
        // Set a smaller limit to avoid issue where endpoint returns incomplete data.
        limit: 6000,
        granularity,
        ...props.filterValues,
        propertyId: props.propertyId,
        utilityType: filters.utilityType,
        utilityMetricType: filters.utilityMetricType,
        periods: energyReport?.periods ? energyReport.periods.filter((item) => selectedPeriods.includes(item)) : []
      });

      window.location.assign(`${BAM_API_BASE_URL}/v1/energy-report/xlsx/export/${url.data}`);
    } catch (error) {
      handleHttpErrors(error);
    }
  };

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return !energyReport ? layoutLoading : generalLayout;
      case Status.None:
        return generalLayout;
      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const layoutLoading = <Loader active inline="centered" size="large" style={{ top: '30vh' }} />;

  const layoutLoadError = <LoadError message={t('energyReport:unableToFetchEnergyData')} retry={reload} />;

  const generalLayout = (
    <OverlayLoader loading={status === Status.Loading} top={'30vh'}>
      <Grid>
        <Grid.Column width={16} className={styles.headerGrid}>
          <div className={styles.graphHeaderContainer}>
            <EnergyReportFilters
              filters={filters}
              setFilters={setFilters}
              granularity={granularity}
              setGranularity={setGranularity}
              areAllMarked={energyReport?.periods && selectedPeriods?.length === energyReport.periods?.length}
              onAllMarkedChange={(checked) => {
                if (checked && energyReport?.periods) {
                  setSelectedPeriods(energyReport.periods);
                } else setSelectedPeriods([]);
              }}
            />

            <div className={styles.chartLegend}>
              <Label circular style={{ backgroundColor: colors.chart1 }} size="tiny" />
              <div>{translateUtilityCombinations(concatUtilityCombinations(filters))}</div>
            </div>
          </div>
        </Grid.Column>

        <Grid.Column width={16}>
          {energyReport && (
            <EnergyReportBarGraph
              energyReport={energyReport}
              selectedPeriods={selectedPeriods}
              onBarClick={(label) =>
                setSelectedPeriods((prevValue) =>
                  prevValue.includes(label) ? prevValue.filter((item) => item !== label) : [...prevValue, label]
                )
              }
              unit={filters.utilityMetricType === UtilityMetricsType.ENERGY_KWH ? 'kWh' : 'm³'}
            />
          )}
        </Grid.Column>

        <Grid.Column width={16}>
          {energyReport && (
            <>
              <div className={styles.tableHeaderContainer}>
                <Header as="h3">{t('energyReport:distibution')}</Header>
                <Button primary onClick={exportEnergyReport}>
                  <Icon>
                    <FontAwesomeIcon fixedWidth icon={faFileExport} />
                  </Icon>
                  {t('common:export')}
                </Button>
              </div>

              <EnergyReportTable
                energyReport={energyReport}
                periods={selectedPeriods}
                granularity={granularity}
                unit={filters.utilityMetricType === UtilityMetricsType.ENERGY_KWH ? 'kWh' : 'm³'}
              />
            </>
          )}
        </Grid.Column>
      </Grid>
    </OverlayLoader>
  );
  return <div>{getLayout()}</div>;
};

export default EnergyReport;
