import React from 'react';
import { Button, Grid, Header, Icon, Loader } from 'semantic-ui-react';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { useNonce } from '../../Utils/hooks';
import LoadError from '../LoadError';
import OverlayLoader from '../OverlayLoader';
import FinancialStatisticsBarGraph from './Components/FinancialStatisticsBarGraph';
import FinancialStatisticsFilters from './Components/FinancialStatisticsFilters';
import FinancialStatisticsGraphLegend from './Components/FinancialStatisticsGraphLegend';
import FinancialStatisticsTable from './Components/FinancialStatisticsTable';
import styles from './FinancialStatistics.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import FinancialStatisticsKPIs from './Components/FinancialStatisticsKPIs';
import { FinancialReportReadView, FinancialReportView } from '../../GeneratedServices';
import { financialReportApi } from '../../Http/Http';
import { BAM_API_BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import { FilterValuesType } from '../../FiltersProvider/FiltersProvider';
import { generatePeriodLabel, getPerAreaValue } from '../../Services/FinancialReportService';

const NUM_PERIODS_AT_LOAD = 4;

interface Props {
  filterValues?: FilterValuesType;
  propertyId?: number;
}

enum Status {
  None,
  Loading,
  LoadError
}

export enum FinancialStatisticsFilterType {
  Income = 'income',
  OPEX = 'opex',
  Maintenance = 'maintenance',
  Management = 'management',
  CapexAndTi = 'capex&ti'
}

const FinancialStatistics: React.FC<Props> = (props) => {
  const { t } = useTranslation(['financialStatistics', 'common']);
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const [status, setStatus] = React.useState<Status>(Status.Loading);
  const [shouldReload, reload] = useNonce();

  const [financialStatistics, setFinancialStatistics] = React.useState<FinancialReportView>();

  const [granularity, setGranularity] = React.useState<'QUARTERLY' | 'YEARLY'>('QUARTERLY');

  const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);

  const [selectedStatisticsType, setSelectedStatisticsType] = React.useState<FinancialStatisticsFilterType[]>([
    FinancialStatisticsFilterType.Income,
    FinancialStatisticsFilterType.Maintenance,
    FinancialStatisticsFilterType.Management,
    FinancialStatisticsFilterType.OPEX
  ]);
  const [areStatisticsPerArea, setStatisticsPerArea] = React.useState<boolean>(false);
  const [includePrognosis, setIncludePrognosis] = React.useState<boolean>(false);

  const formattedFinancialStatistics: FinancialReportView | undefined = React.useMemo(
    () =>
      financialStatistics != null && areStatisticsPerArea
        ? {
            periods: financialStatistics.periods.map((item) => {
              const newStatistics: FinancialReportReadView = {
                ...item,
                capexAndTi: getPerAreaValue(item.capexAndTi, item.actualArea, item.plannedArea),
                cleaning: getPerAreaValue(item.cleaning, item.actualArea, item.plannedArea),
                cooling: getPerAreaValue(item.cooling, item.actualArea, item.plannedArea),
                discounts: getPerAreaValue(item.discounts, item.actualArea, item.plannedArea),
                electricity: getPerAreaValue(item.electricity, item.actualArea, item.plannedArea),
                waste: getPerAreaValue(item.waste, item.actualArea, item.plannedArea),
                snowRemovalAndGardening: getPerAreaValue(
                  item.snowRemovalAndGardening,
                  item.actualArea,
                  item.plannedArea
                ),
                heating: getPerAreaValue(item.heating, item.actualArea, item.plannedArea),
                insurance: getPerAreaValue(item.insurance, item.actualArea, item.plannedArea),
                leaseholdFee: getPerAreaValue(item.leaseholdFee, item.actualArea, item.plannedArea),
                maintenance: getPerAreaValue(item.maintenance, item.actualArea, item.plannedArea),
                marketing: getPerAreaValue(item.marketing, item.actualArea, item.plannedArea),
                NOI: getPerAreaValue(item.NOI, item.actualArea, item.plannedArea),
                OPEX: getPerAreaValue(item.OPEX, item.actualArea, item.plannedArea),
                otherIncome: getPerAreaValue(item.otherIncome, item.actualArea, item.plannedArea),
                otherOperatingExpenses: getPerAreaValue(item.otherOperatingExpenses, item.actualArea, item.plannedArea),
                periodicMaintenance: getPerAreaValue(item.periodicMaintenance, item.actualArea, item.plannedArea),
                propertyManagement: getPerAreaValue(item.propertyManagement, item.actualArea, item.plannedArea),
                realEstateTaxes: getPerAreaValue(item.realEstateTaxes, item.actualArea, item.plannedArea),
                rent: getPerAreaValue(item.rent, item.actualArea, item.plannedArea),
                repairs: getPerAreaValue(item.repairs, item.actualArea, item.plannedArea),
                security: getPerAreaValue(item.security, item.actualArea, item.plannedArea),
                serviceAgreements: getPerAreaValue(item.serviceAgreements, item.actualArea, item.plannedArea),
                supplements: getPerAreaValue(item.supplements, item.actualArea, item.plannedArea),
                totalCosts: getPerAreaValue(item.totalCosts, item.actualArea, item.plannedArea),
                totalIncome: getPerAreaValue(item.totalIncome, item.actualArea, item.plannedArea),
                water: getPerAreaValue(item.water, item.actualArea, item.plannedArea)
              };
              return newStatistics;
            }),
            totalCapexAndTi: financialStatistics.totalCapexAndTi
          }
        : financialStatistics,
    [financialStatistics, areStatisticsPerArea]
  );

  const selectedFinancialStatistics = React.useMemo(
    () => formattedFinancialStatistics?.periods.filter((item) => selectedLabels.includes(generatePeriodLabel(item))),
    [formattedFinancialStatistics, selectedLabels]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const loadFinancialData = async () => {
      try {
        setStatus(Status.Loading);

        const result = await financialReportApi.financialReportResourceGetReport(
          {
            limit: 1000,
            granularity,
            ...props.filterValues,
            propertyId: props.propertyId,
            includePrognosis
          },
          {
            signal: abortController.signal
          }
        );

        setFinancialStatistics(result.data);
        setSelectedLabels(
          result.data.periods.length < NUM_PERIODS_AT_LOAD
            ? result.data.periods.map((item) => generatePeriodLabel(item))
            : result.data.periods.slice(-NUM_PERIODS_AT_LOAD).map((item) => generatePeriodLabel(item))
        );

        setStatus(Status.None);
      } catch (error) {
        handleHttpErrors(error) && setStatus(Status.LoadError);
      }
    };

    loadFinancialData();

    return () => {
      abortController.abort();
    };
  }, [handleHttpErrors, props.filterValues, granularity, props.propertyId, shouldReload, includePrognosis]);

  const exportFinancialStatistics = async () => {
    try {
      var url = await financialReportApi.financialReportResourceExportExcelLeasesUrl({
        granularity,
        ...props.filterValues,
        propertyId: props.propertyId,
        periods: financialStatistics
          ? financialStatistics.periods.filter((item) => selectedLabels.includes(item.label)).map((item) => item.label)
          : []
      });

      window.location.assign(`${BAM_API_BASE_URL}/v1/financial-report/xlsx/export/${url.data}`);
    } catch (error) {
      handleHttpErrors(error);
    }
  };

  const getLayout = () => {
    switch (status) {
      case Status.Loading:
        return financialStatistics && financialStatistics.periods.length !== 0 ? generalLayout : layoutLoading;
      case Status.None:
        return generalLayout;
      case Status.LoadError:
        return layoutLoadError;
    }
  };

  const layoutLoading = <Loader inline="centered" active size="large" style={{ top: '30vh' }} />;

  const layoutLoadError = <LoadError message={t('financialStatistics:unableToFetchEconomyData')} retry={reload} />;

  const generalLayout = (
    <OverlayLoader loading={status === Status.Loading} top={'40vh'}>
      <Grid>
        <Grid.Column widescreen={9} largeScreen={7} computer={10} tablet={16} className={styles.headerGrid}>
          <div className={styles.graphHeaderContainer}>
            <FinancialStatisticsFilters
              setGranularity={setGranularity}
              selectedStatisticsType={selectedStatisticsType}
              setSelectedStatisticsType={setSelectedStatisticsType}
              areStatisticsPerArea={areStatisticsPerArea}
              granularity={granularity}
              onStatisticsPerAreaChange={(checked) => setStatisticsPerArea(checked)}
              areAllMarked={
                financialStatistics && selectedFinancialStatistics?.length === financialStatistics?.periods.length
              }
              onAllMarkedChange={(checked) => {
                if (checked && financialStatistics) {
                  setSelectedLabels(financialStatistics?.periods.map((item) => generatePeriodLabel(item)));
                } else setSelectedLabels([]);
              }}
              includePrognosis={includePrognosis}
              setIncludePrognosis={setIncludePrognosis}
            />

            <FinancialStatisticsGraphLegend />
          </div>
        </Grid.Column>
        <Grid.Column widescreen={7} largeScreen={9} computer={6} tablet={16} className={styles.paddlessSection} />

        <Grid.Column widescreen={9} largeScreen={7} computer={10} tablet={16}>
          <FinancialStatisticsBarGraph
            selectedLabels={selectedLabels}
            areStatisticsPerArea={areStatisticsPerArea}
            selectedStatisticsType={selectedStatisticsType}
            financialStatistics={formattedFinancialStatistics?.periods ?? []}
            onBarClick={(label) =>
              setSelectedLabels((prevValue) =>
                prevValue.includes(label) ? prevValue.filter((item) => item !== label) : [...prevValue, label]
              )
            }
          />
        </Grid.Column>

        <>
          {selectedFinancialStatistics && (
            <FinancialStatisticsKPIs
              financialStatistics={selectedFinancialStatistics}
              areStatisticsPerArea={areStatisticsPerArea}
              totalCapexAndTi={financialStatistics?.totalCapexAndTi.actual ?? 0}
              estimatedCapexAndTi={financialStatistics?.totalCapexAndTi.planned ?? 0}
            />
          )}
        </>

        <Grid.Column width={16}>
          <div className={styles.tableHeaderContainer}>
            <Header as="h3">{t('common:results')}</Header>

            <Button primary onClick={exportFinancialStatistics}>
              <Icon>
                <FontAwesomeIcon fixedWidth icon={faFileExport} />
              </Icon>
              {t('common:export')}
            </Button>
          </div>

          <FinancialStatisticsTable
            financialStatistics={selectedFinancialStatistics ?? []}
            areStatisticsPerArea={areStatisticsPerArea}
          />
        </Grid.Column>
      </Grid>
    </OverlayLoader>
  );
  return <div>{getLayout()}</div>;
};

export default FinancialStatistics;
