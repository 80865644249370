import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Grid, Header, Label, Modal } from 'semantic-ui-react';
import {
  EmbeddedGovernmentInspectionPropertyReadView,
  GovernmentInspectionItemReadView,
  GovernmentInspectionListItemViewCategoryEnum
} from '../../GeneratedServices';
import { globalStateCTX } from '../../GlobalState/GlobalState';
import { governmentInspectionApi } from '../../Http/Http';
import {
  getGovernmentInspectionStatusColor,
  translateGovernmentInspectionCategory,
  translateGovernmentInspectionItemAssignee,
  translateGovernmentInspectionItemStatus
} from '../../Services/GovernmentInspectionService.types';
import GovernmentInspectionItemResultsTable from './GovernmentInspectionItemResultTable';

interface Props {
  item: GovernmentInspectionItemReadView;
  property: EmbeddedGovernmentInspectionPropertyReadView;
  category: GovernmentInspectionListItemViewCategoryEnum;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onOrderedChange: (ordered: boolean) => void;
  onPerformClick: () => void;
  onItemResultClick: (workOrderId: number) => void;
  itemResultsReloadCount: number;
}

enum Status {
  Saving,
  None
}

const GovernmentInspectionItemModal: React.FC<Props> = (props) => {
  const { handleHttpErrors } = React.useContext(globalStateCTX);
  const { t } = useTranslation(['inspections', 'common']);

  const [status, setStatus] = React.useState<Status>(Status.None);

  const handleOrderedChange = async (ordered: boolean) => {
    try {
      setStatus(Status.Saving);

      const { data } = await governmentInspectionApi.governmentInspectionResourceUpdateItem({
        id: props.item.id,
        governmentInspectionSingleItemUpdateView: {
          assignee: props.item.assignee,
          info: props.item.info,
          initialInspectionDeadline: props.item.initialInspectionDeadline,
          title: props.item.title,
          ordered
        }
      });

      props.onOrderedChange(data.ordered);
    } catch (error) {
      handleHttpErrors(error);
    } finally {
      setStatus(Status.None);
    }
  };

  return (
    <Modal open onClose={props.onClose} closeIcon={status === Status.None} closeOnDimmerClick={false}>
      <Modal.Header style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        {`${t('inspections:inspectionItem')} ${props.item.title}`}
        <Label size="large" color={getGovernmentInspectionStatusColor(props.item.status)}>
          {translateGovernmentInspectionItemStatus(props.item.status)}
        </Label>
      </Modal.Header>

      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Header as="h5">{t('common:property')}</Header>
              <Label size="large">{props.property.name}</Label>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h5">{t('common:category')}</Header>
              <Label size="large">{translateGovernmentInspectionCategory(props.category)}</Label>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h5">{t('inspections:responsible')}</Header>
              <Label size="large">{translateGovernmentInspectionItemAssignee(props.item.assignee)}</Label>
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as="h5">{t('inspections:nextDeadline')}</Header>
              <div>{props.item.nextInspectionDeadline}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h5">{t('common:description')}</Header>
              <div>{props.item.info ?? '-'}</div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Checkbox
                checked={props.item.ordered}
                disabled={status === Status.Saving}
                label={t('inspections:inspectionOrdered')}
                onChange={(e, d) => handleOrderedChange(d.checked ?? false)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Header as="h5">{t('inspections:previousInspections')}</Header>
        <GovernmentInspectionItemResultsTable
          itemId={props.item.id}
          reloadCount={props.itemResultsReloadCount}
          onItemResultClick={props.onItemResultClick}
        />
      </Modal.Content>

      <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button color="red" onClick={props.onDelete}>
          {t('inspections:deleteItem')}
        </Button>
        <Button color="blue" onClick={props.onEdit}>
          {t('inspections:editItem')}
        </Button>
        <Button primary onClick={props.onPerformClick}>
          {t('inspections:performInspectionForThisObjectOnly')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default GovernmentInspectionItemModal;
