/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorView } from '../model';
// @ts-ignore
import { GovernmentInspectionCreateView } from '../model';
// @ts-ignore
import { GovernmentInspectionItemReadView } from '../model';
// @ts-ignore
import { GovernmentInspectionItemResultListView } from '../model';
// @ts-ignore
import { GovernmentInspectionListItemView } from '../model';
// @ts-ignore
import { GovernmentInspectionListView } from '../model';
// @ts-ignore
import { GovernmentInspectionSingleItemCreateView } from '../model';
// @ts-ignore
import { GovernmentInspectionSingleItemUpdateView } from '../model';
// @ts-ignore
import { GovernmentInspectionUpdateView } from '../model';
// @ts-ignore
import { GovernmentInspectionWorkOrderCreateView } from '../model';
// @ts-ignore
import { GovernmentInspectionWorkOrderListView } from '../model';
// @ts-ignore
import { GovernmentInspectionWorkOrderPerformView } from '../model';
// @ts-ignore
import { GovernmentInspectionWorkOrderReadView } from '../model';
/**
 * GovernmentInspectionApi - axios parameter creator
 * @export
 */
export const GovernmentInspectionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List government inspection item results
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [governmentInspectionWorkOrderId] Filter by government inspection work order
     * @param {number} [itemId] Filter by government inspection item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionItemResultResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      governmentInspectionWorkOrderId?: number,
      itemId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspection-item-results`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (governmentInspectionWorkOrderId !== undefined) {
        localVarQueryParameter['governmentInspectionWorkOrderId'] = governmentInspectionWorkOrderId;
      }

      if (itemId !== undefined) {
        localVarQueryParameter['itemId'] = itemId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a government inspection
     * @param {GovernmentInspectionCreateView} [governmentInspectionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceCreate: async (
      governmentInspectionCreateView?: GovernmentInspectionCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        governmentInspectionCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a government inspection item
     * @param {GovernmentInspectionSingleItemCreateView} [governmentInspectionSingleItemCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceCreateItem: async (
      governmentInspectionSingleItemCreateView?: GovernmentInspectionSingleItemCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspection-items`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        governmentInspectionSingleItemCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Delete a government inspection
     * @param {number} id Government inspection ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionResourceDelete', 'id', id);
      const localVarPath = `/v1/government-inspections/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Deletes a government inspection item
     * @param {number} id Government inspection item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceDeleteInspectionItem: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionResourceDeleteInspectionItem', 'id', id);
      const localVarPath = `/v1/government-inspection-items/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Export government inspections
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceExportExcelSummary: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('governmentInspectionResourceExportExcelSummary', 'token', token);
      const localVarPath = `/v1/government-inspections/xlsx/export/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get a token to export government inspection summary
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [propertyId] Filter by property
     * @param {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'} [category] Filter by government inspection category
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceExportExcelSummaryUrl: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      propertyId?: number,
      category?:
        | 'OVK'
        | 'ELEVATOR'
        | 'DOOR'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'ELECTRICITY_AUDIT'
        | 'REFRIGERANT'
        | 'OIL_SEPARATOR'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'ENERGY_DECLARATION'
        | 'PRESSURE_VESSELS'
        | 'SHELTER',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      propertyIds?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspections/xlsx/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyAssigneeId !== undefined) {
        localVarQueryParameter['propertyAssigneeId'] = propertyAssigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (propertyIds) {
        localVarQueryParameter['propertyIds'] = propertyIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List government inspections
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [propertyId] Filter by property
     * @param {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'} [category] Filter by government inspection category
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      propertyId?: number,
      category?:
        | 'OVK'
        | 'ELEVATOR'
        | 'DOOR'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'ELECTRICITY_AUDIT'
        | 'REFRIGERANT'
        | 'OIL_SEPARATOR'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'ENERGY_DECLARATION'
        | 'PRESSURE_VESSELS'
        | 'SHELTER',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      propertyIds?: Array<number>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (portfolioId !== undefined) {
        localVarQueryParameter['portfolioId'] = portfolioId;
      }

      if (propertyAssigneeId !== undefined) {
        localVarQueryParameter['propertyAssigneeId'] = propertyAssigneeId;
      }

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (search !== undefined) {
        localVarQueryParameter['search'] = search;
      }

      if (organisationIds) {
        localVarQueryParameter['organisationIds'] = organisationIds;
      }

      if (portfolioIds) {
        localVarQueryParameter['portfolioIds'] = portfolioIds;
      }

      if (propertySubscriptionUserIds) {
        localVarQueryParameter['propertySubscriptionUserIds'] = propertySubscriptionUserIds;
      }

      if (municipalities) {
        localVarQueryParameter['municipalities'] = municipalities;
      }

      if (propertyIds) {
        localVarQueryParameter['propertyIds'] = propertyIds;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a government inspection
     * @param {number} id Government inspection ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionResourceRead', 'id', id);
      const localVarPath = `/v1/government-inspections/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a government inspection
     * @param {number} id Government inspection ID
     * @param {GovernmentInspectionUpdateView} [governmentInspectionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceUpdate: async (
      id: number,
      governmentInspectionUpdateView?: GovernmentInspectionUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionResourceUpdate', 'id', id);
      const localVarPath = `/v1/government-inspections/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        governmentInspectionUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update a government inspection item
     * @param {number} id Government inspection item ID
     * @param {GovernmentInspectionSingleItemUpdateView} [governmentInspectionSingleItemUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceUpdateItem: async (
      id: number,
      governmentInspectionSingleItemUpdateView?: GovernmentInspectionSingleItemUpdateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionResourceUpdateItem', 'id', id);
      const localVarPath = `/v1/government-inspection-items/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        governmentInspectionSingleItemUpdateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create a government inspection work order
     * @param {GovernmentInspectionWorkOrderCreateView} [governmentInspectionWorkOrderCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourceCreateAndPerform: async (
      governmentInspectionWorkOrderCreateView?: GovernmentInspectionWorkOrderCreateView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspection-work-orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        governmentInspectionWorkOrderCreateView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List government inspection work orders
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [governmentInspectionId] Filter by government inspection
     * @param {number} [faultReportWorkOrderId] Filter by fault report work order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourceList: async (
      limit?: number,
      offset?: number,
      organisationId?: number,
      governmentInspectionId?: number,
      faultReportWorkOrderId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v1/government-inspection-work-orders`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (organisationId !== undefined) {
        localVarQueryParameter['organisationId'] = organisationId;
      }

      if (governmentInspectionId !== undefined) {
        localVarQueryParameter['governmentInspectionId'] = governmentInspectionId;
      }

      if (faultReportWorkOrderId !== undefined) {
        localVarQueryParameter['faultReportWorkOrderId'] = faultReportWorkOrderId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Perform government inspection work order
     * @param {number} id Government inspection work order ID
     * @param {GovernmentInspectionWorkOrderPerformView} [governmentInspectionWorkOrderPerformView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourcePerform: async (
      id: number,
      governmentInspectionWorkOrderPerformView?: GovernmentInspectionWorkOrderPerformView,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionWorkOrderResourcePerform', 'id', id);
      const localVarPath = `/v1/government-inspection-work-orders/{id}/perform`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        governmentInspectionWorkOrderPerformView,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Read a government inspection work order
     * @param {number} id Government inspection work order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourceRead: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('governmentInspectionWorkOrderResourceRead', 'id', id);
      const localVarPath = `/v1/government-inspection-work-orders/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication default required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * GovernmentInspectionApi - functional programming interface
 * @export
 */
export const GovernmentInspectionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GovernmentInspectionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List government inspection item results
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [governmentInspectionWorkOrderId] Filter by government inspection work order
     * @param {number} [itemId] Filter by government inspection item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionItemResultResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      governmentInspectionWorkOrderId?: number,
      itemId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionItemResultListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionItemResultResourceList(
        limit,
        offset,
        organisationId,
        governmentInspectionWorkOrderId,
        itemId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a government inspection
     * @param {GovernmentInspectionCreateView} [governmentInspectionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceCreate(
      governmentInspectionCreateView?: GovernmentInspectionCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionListItemView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceCreate(
        governmentInspectionCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a government inspection item
     * @param {GovernmentInspectionSingleItemCreateView} [governmentInspectionSingleItemCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceCreateItem(
      governmentInspectionSingleItemCreateView?: GovernmentInspectionSingleItemCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionItemReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceCreateItem(
        governmentInspectionSingleItemCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete a government inspection
     * @param {number} id Government inspection ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceDelete(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceDelete(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Deletes a government inspection item
     * @param {number} id Government inspection item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceDeleteInspectionItem(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceDeleteInspectionItem(
        id,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Export government inspections
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceExportExcelSummary(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceExportExcelSummary(
        token,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a token to export government inspection summary
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [propertyId] Filter by property
     * @param {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'} [category] Filter by government inspection category
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceExportExcelSummaryUrl(
      limit?: number,
      offset?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      propertyId?: number,
      category?:
        | 'OVK'
        | 'ELEVATOR'
        | 'DOOR'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'ELECTRICITY_AUDIT'
        | 'REFRIGERANT'
        | 'OIL_SEPARATOR'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'ENERGY_DECLARATION'
        | 'PRESSURE_VESSELS'
        | 'SHELTER',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      propertyIds?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceExportExcelSummaryUrl(
        limit,
        offset,
        organisationId,
        portfolioId,
        propertyAssigneeId,
        propertyId,
        category,
        search,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        propertyIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List government inspections
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [propertyId] Filter by property
     * @param {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'} [category] Filter by government inspection category
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      propertyId?: number,
      category?:
        | 'OVK'
        | 'ELEVATOR'
        | 'DOOR'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'ELECTRICITY_AUDIT'
        | 'REFRIGERANT'
        | 'OIL_SEPARATOR'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'ENERGY_DECLARATION'
        | 'PRESSURE_VESSELS'
        | 'SHELTER',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      propertyIds?: Array<number>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceList(
        limit,
        offset,
        organisationId,
        portfolioId,
        propertyAssigneeId,
        propertyId,
        category,
        search,
        organisationIds,
        portfolioIds,
        propertySubscriptionUserIds,
        municipalities,
        propertyIds,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a government inspection
     * @param {number} id Government inspection ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionListItemView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a government inspection
     * @param {number} id Government inspection ID
     * @param {GovernmentInspectionUpdateView} [governmentInspectionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceUpdate(
      id: number,
      governmentInspectionUpdateView?: GovernmentInspectionUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionListItemView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceUpdate(
        id,
        governmentInspectionUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a government inspection item
     * @param {number} id Government inspection item ID
     * @param {GovernmentInspectionSingleItemUpdateView} [governmentInspectionSingleItemUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionResourceUpdateItem(
      id: number,
      governmentInspectionSingleItemUpdateView?: GovernmentInspectionSingleItemUpdateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionItemReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionResourceUpdateItem(
        id,
        governmentInspectionSingleItemUpdateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a government inspection work order
     * @param {GovernmentInspectionWorkOrderCreateView} [governmentInspectionWorkOrderCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionWorkOrderResourceCreateAndPerform(
      governmentInspectionWorkOrderCreateView?: GovernmentInspectionWorkOrderCreateView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionWorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionWorkOrderResourceCreateAndPerform(
        governmentInspectionWorkOrderCreateView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List government inspection work orders
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [governmentInspectionId] Filter by government inspection
     * @param {number} [faultReportWorkOrderId] Filter by fault report work order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionWorkOrderResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      governmentInspectionId?: number,
      faultReportWorkOrderId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionWorkOrderListView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionWorkOrderResourceList(
        limit,
        offset,
        organisationId,
        governmentInspectionId,
        faultReportWorkOrderId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Perform government inspection work order
     * @param {number} id Government inspection work order ID
     * @param {GovernmentInspectionWorkOrderPerformView} [governmentInspectionWorkOrderPerformView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionWorkOrderResourcePerform(
      id: number,
      governmentInspectionWorkOrderPerformView?: GovernmentInspectionWorkOrderPerformView,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionWorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionWorkOrderResourcePerform(
        id,
        governmentInspectionWorkOrderPerformView,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Read a government inspection work order
     * @param {number} id Government inspection work order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async governmentInspectionWorkOrderResourceRead(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GovernmentInspectionWorkOrderReadView>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.governmentInspectionWorkOrderResourceRead(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * GovernmentInspectionApi - factory interface
 * @export
 */
export const GovernmentInspectionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = GovernmentInspectionApiFp(configuration);
  return {
    /**
     *
     * @summary List government inspection item results
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [governmentInspectionWorkOrderId] Filter by government inspection work order
     * @param {number} [itemId] Filter by government inspection item
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionItemResultResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      governmentInspectionWorkOrderId?: number,
      itemId?: number,
      options?: any
    ): AxiosPromise<GovernmentInspectionItemResultListView> {
      return localVarFp
        .governmentInspectionItemResultResourceList(
          limit,
          offset,
          organisationId,
          governmentInspectionWorkOrderId,
          itemId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a government inspection
     * @param {GovernmentInspectionCreateView} [governmentInspectionCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceCreate(
      governmentInspectionCreateView?: GovernmentInspectionCreateView,
      options?: any
    ): AxiosPromise<GovernmentInspectionListItemView> {
      return localVarFp
        .governmentInspectionResourceCreate(governmentInspectionCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a government inspection item
     * @param {GovernmentInspectionSingleItemCreateView} [governmentInspectionSingleItemCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceCreateItem(
      governmentInspectionSingleItemCreateView?: GovernmentInspectionSingleItemCreateView,
      options?: any
    ): AxiosPromise<GovernmentInspectionItemReadView> {
      return localVarFp
        .governmentInspectionResourceCreateItem(governmentInspectionSingleItemCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a government inspection
     * @param {number} id Government inspection ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceDelete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.governmentInspectionResourceDelete(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Deletes a government inspection item
     * @param {number} id Government inspection item ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceDeleteInspectionItem(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .governmentInspectionResourceDeleteInspectionItem(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export government inspections
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceExportExcelSummary(token: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .governmentInspectionResourceExportExcelSummary(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a token to export government inspection summary
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [propertyId] Filter by property
     * @param {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'} [category] Filter by government inspection category
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceExportExcelSummaryUrl(
      limit?: number,
      offset?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      propertyId?: number,
      category?:
        | 'OVK'
        | 'ELEVATOR'
        | 'DOOR'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'ELECTRICITY_AUDIT'
        | 'REFRIGERANT'
        | 'OIL_SEPARATOR'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'ENERGY_DECLARATION'
        | 'PRESSURE_VESSELS'
        | 'SHELTER',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      propertyIds?: Array<number>,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .governmentInspectionResourceExportExcelSummaryUrl(
          limit,
          offset,
          organisationId,
          portfolioId,
          propertyAssigneeId,
          propertyId,
          category,
          search,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          propertyIds,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List government inspections
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [portfolioId] Filter by property\&#39;s portfolio
     * @param {number} [propertyAssigneeId] Filter by property\&#39;s assignee
     * @param {number} [propertyId] Filter by property
     * @param {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'} [category] Filter by government inspection category
     * @param {string} [search] Filter by search term
     * @param {Array<number>} [organisationIds] Filter by organisations
     * @param {Array<number>} [portfolioIds] Filter by portfolios
     * @param {Array<number>} [propertySubscriptionUserIds] Filter by property subscription users
     * @param {Array<string>} [municipalities] Filter by municipalities
     * @param {Array<number>} [propertyIds] Filter by properties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      portfolioId?: number,
      propertyAssigneeId?: number,
      propertyId?: number,
      category?:
        | 'OVK'
        | 'ELEVATOR'
        | 'DOOR'
        | 'LIFT_TABLE'
        | 'LOADING_DOCK'
        | 'AUDIT_INSPECTION_FIRE_ALARM'
        | 'AUDIT_INSPECTION_SPRINKLER'
        | 'ELECTRICITY_AUDIT'
        | 'REFRIGERANT'
        | 'OIL_SEPARATOR'
        | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
        | 'ENERGY_DECLARATION'
        | 'PRESSURE_VESSELS'
        | 'SHELTER',
      search?: string,
      organisationIds?: Array<number>,
      portfolioIds?: Array<number>,
      propertySubscriptionUserIds?: Array<number>,
      municipalities?: Array<string>,
      propertyIds?: Array<number>,
      options?: any
    ): AxiosPromise<GovernmentInspectionListView> {
      return localVarFp
        .governmentInspectionResourceList(
          limit,
          offset,
          organisationId,
          portfolioId,
          propertyAssigneeId,
          propertyId,
          category,
          search,
          organisationIds,
          portfolioIds,
          propertySubscriptionUserIds,
          municipalities,
          propertyIds,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a government inspection
     * @param {number} id Government inspection ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceRead(id: number, options?: any): AxiosPromise<GovernmentInspectionListItemView> {
      return localVarFp.governmentInspectionResourceRead(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a government inspection
     * @param {number} id Government inspection ID
     * @param {GovernmentInspectionUpdateView} [governmentInspectionUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceUpdate(
      id: number,
      governmentInspectionUpdateView?: GovernmentInspectionUpdateView,
      options?: any
    ): AxiosPromise<GovernmentInspectionListItemView> {
      return localVarFp
        .governmentInspectionResourceUpdate(id, governmentInspectionUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a government inspection item
     * @param {number} id Government inspection item ID
     * @param {GovernmentInspectionSingleItemUpdateView} [governmentInspectionSingleItemUpdateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionResourceUpdateItem(
      id: number,
      governmentInspectionSingleItemUpdateView?: GovernmentInspectionSingleItemUpdateView,
      options?: any
    ): AxiosPromise<GovernmentInspectionItemReadView> {
      return localVarFp
        .governmentInspectionResourceUpdateItem(id, governmentInspectionSingleItemUpdateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a government inspection work order
     * @param {GovernmentInspectionWorkOrderCreateView} [governmentInspectionWorkOrderCreateView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourceCreateAndPerform(
      governmentInspectionWorkOrderCreateView?: GovernmentInspectionWorkOrderCreateView,
      options?: any
    ): AxiosPromise<GovernmentInspectionWorkOrderReadView> {
      return localVarFp
        .governmentInspectionWorkOrderResourceCreateAndPerform(governmentInspectionWorkOrderCreateView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List government inspection work orders
     * @param {number} [limit] Maximum amount of items per page
     * @param {number} [offset] Page offset
     * @param {number} [organisationId] Filter by organisation
     * @param {number} [governmentInspectionId] Filter by government inspection
     * @param {number} [faultReportWorkOrderId] Filter by fault report work order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourceList(
      limit?: number,
      offset?: number,
      organisationId?: number,
      governmentInspectionId?: number,
      faultReportWorkOrderId?: number,
      options?: any
    ): AxiosPromise<GovernmentInspectionWorkOrderListView> {
      return localVarFp
        .governmentInspectionWorkOrderResourceList(
          limit,
          offset,
          organisationId,
          governmentInspectionId,
          faultReportWorkOrderId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Perform government inspection work order
     * @param {number} id Government inspection work order ID
     * @param {GovernmentInspectionWorkOrderPerformView} [governmentInspectionWorkOrderPerformView]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourcePerform(
      id: number,
      governmentInspectionWorkOrderPerformView?: GovernmentInspectionWorkOrderPerformView,
      options?: any
    ): AxiosPromise<GovernmentInspectionWorkOrderReadView> {
      return localVarFp
        .governmentInspectionWorkOrderResourcePerform(id, governmentInspectionWorkOrderPerformView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Read a government inspection work order
     * @param {number} id Government inspection work order ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    governmentInspectionWorkOrderResourceRead(
      id: number,
      options?: any
    ): AxiosPromise<GovernmentInspectionWorkOrderReadView> {
      return localVarFp
        .governmentInspectionWorkOrderResourceRead(id, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * Request parameters for governmentInspectionItemResultResourceList operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionItemResultResourceListRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionItemResultResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionItemResultResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionItemResultResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionItemResultResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by government inspection work order
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionItemResultResourceList
   */
  readonly governmentInspectionWorkOrderId?: number;

  /**
   * Filter by government inspection item
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionItemResultResourceList
   */
  readonly itemId?: number;
}

/**
 * Request parameters for governmentInspectionResourceCreate operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceCreateRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceCreateRequest {
  /**
   *
   * @type {GovernmentInspectionCreateView}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceCreate
   */
  readonly governmentInspectionCreateView?: GovernmentInspectionCreateView;
}

/**
 * Request parameters for governmentInspectionResourceCreateItem operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceCreateItemRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceCreateItemRequest {
  /**
   *
   * @type {GovernmentInspectionSingleItemCreateView}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceCreateItem
   */
  readonly governmentInspectionSingleItemCreateView?: GovernmentInspectionSingleItemCreateView;
}

/**
 * Request parameters for governmentInspectionResourceDelete operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceDeleteRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceDeleteRequest {
  /**
   * Government inspection ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceDelete
   */
  readonly id: number;
}

/**
 * Request parameters for governmentInspectionResourceDeleteInspectionItem operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceDeleteInspectionItemRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceDeleteInspectionItemRequest {
  /**
   * Government inspection item ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceDeleteInspectionItem
   */
  readonly id: number;
}

/**
 * Request parameters for governmentInspectionResourceExportExcelSummary operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryRequest {
  /**
   *
   * @type {string}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummary
   */
  readonly token: string;
}

/**
 * Request parameters for governmentInspectionResourceExportExcelSummaryUrl operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrlRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrlRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly organisationId?: number;

  /**
   * Filter by property\&#39;s portfolio
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly portfolioId?: number;

  /**
   * Filter by property\&#39;s assignee
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly propertyAssigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly propertyId?: number;

  /**
   * Filter by government inspection category
   * @type {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly category?:
    | 'OVK'
    | 'ELEVATOR'
    | 'DOOR'
    | 'LIFT_TABLE'
    | 'LOADING_DOCK'
    | 'AUDIT_INSPECTION_FIRE_ALARM'
    | 'AUDIT_INSPECTION_SPRINKLER'
    | 'ELECTRICITY_AUDIT'
    | 'REFRIGERANT'
    | 'OIL_SEPARATOR'
    | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
    | 'ENERGY_DECLARATION'
    | 'PRESSURE_VESSELS'
    | 'SHELTER';

  /**
   * Filter by search term
   * @type {string}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly search?: string;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by properties
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrl
   */
  readonly propertyIds?: Array<number>;
}

/**
 * Request parameters for governmentInspectionResourceList operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceListRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by property\&#39;s portfolio
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly portfolioId?: number;

  /**
   * Filter by property\&#39;s assignee
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly propertyAssigneeId?: number;

  /**
   * Filter by property
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly propertyId?: number;

  /**
   * Filter by government inspection category
   * @type {'OVK' | 'ELEVATOR' | 'DOOR' | 'LIFT_TABLE' | 'LOADING_DOCK' | 'AUDIT_INSPECTION_FIRE_ALARM' | 'AUDIT_INSPECTION_SPRINKLER' | 'ELECTRICITY_AUDIT' | 'REFRIGERANT' | 'OIL_SEPARATOR' | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK' | 'ENERGY_DECLARATION' | 'PRESSURE_VESSELS' | 'SHELTER'}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly category?:
    | 'OVK'
    | 'ELEVATOR'
    | 'DOOR'
    | 'LIFT_TABLE'
    | 'LOADING_DOCK'
    | 'AUDIT_INSPECTION_FIRE_ALARM'
    | 'AUDIT_INSPECTION_SPRINKLER'
    | 'ELECTRICITY_AUDIT'
    | 'REFRIGERANT'
    | 'OIL_SEPARATOR'
    | 'SBA_SYSTEMATIC_FIRE_PROTECTION_WORK'
    | 'ENERGY_DECLARATION'
    | 'PRESSURE_VESSELS'
    | 'SHELTER';

  /**
   * Filter by search term
   * @type {string}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly search?: string;

  /**
   * Filter by organisations
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly organisationIds?: Array<number>;

  /**
   * Filter by portfolios
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly portfolioIds?: Array<number>;

  /**
   * Filter by property subscription users
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly propertySubscriptionUserIds?: Array<number>;

  /**
   * Filter by municipalities
   * @type {Array<string>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly municipalities?: Array<string>;

  /**
   * Filter by properties
   * @type {Array<number>}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceList
   */
  readonly propertyIds?: Array<number>;
}

/**
 * Request parameters for governmentInspectionResourceRead operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceReadRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceReadRequest {
  /**
   * Government inspection ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceRead
   */
  readonly id: number;
}

/**
 * Request parameters for governmentInspectionResourceUpdate operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceUpdateRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceUpdateRequest {
  /**
   * Government inspection ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceUpdate
   */
  readonly id: number;

  /**
   *
   * @type {GovernmentInspectionUpdateView}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceUpdate
   */
  readonly governmentInspectionUpdateView?: GovernmentInspectionUpdateView;
}

/**
 * Request parameters for governmentInspectionResourceUpdateItem operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionResourceUpdateItemRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionResourceUpdateItemRequest {
  /**
   * Government inspection item ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceUpdateItem
   */
  readonly id: number;

  /**
   *
   * @type {GovernmentInspectionSingleItemUpdateView}
   * @memberof GovernmentInspectionApiGovernmentInspectionResourceUpdateItem
   */
  readonly governmentInspectionSingleItemUpdateView?: GovernmentInspectionSingleItemUpdateView;
}

/**
 * Request parameters for governmentInspectionWorkOrderResourceCreateAndPerform operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourceCreateAndPerformRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourceCreateAndPerformRequest {
  /**
   *
   * @type {GovernmentInspectionWorkOrderCreateView}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceCreateAndPerform
   */
  readonly governmentInspectionWorkOrderCreateView?: GovernmentInspectionWorkOrderCreateView;
}

/**
 * Request parameters for governmentInspectionWorkOrderResourceList operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourceListRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourceListRequest {
  /**
   * Maximum amount of items per page
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceList
   */
  readonly limit?: number;

  /**
   * Page offset
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceList
   */
  readonly offset?: number;

  /**
   * Filter by organisation
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceList
   */
  readonly organisationId?: number;

  /**
   * Filter by government inspection
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceList
   */
  readonly governmentInspectionId?: number;

  /**
   * Filter by fault report work order
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceList
   */
  readonly faultReportWorkOrderId?: number;
}

/**
 * Request parameters for governmentInspectionWorkOrderResourcePerform operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourcePerformRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourcePerformRequest {
  /**
   * Government inspection work order ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourcePerform
   */
  readonly id: number;

  /**
   *
   * @type {GovernmentInspectionWorkOrderPerformView}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourcePerform
   */
  readonly governmentInspectionWorkOrderPerformView?: GovernmentInspectionWorkOrderPerformView;
}

/**
 * Request parameters for governmentInspectionWorkOrderResourceRead operation in GovernmentInspectionApi.
 * @export
 * @interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourceReadRequest
 */
export interface GovernmentInspectionApiGovernmentInspectionWorkOrderResourceReadRequest {
  /**
   * Government inspection work order ID
   * @type {number}
   * @memberof GovernmentInspectionApiGovernmentInspectionWorkOrderResourceRead
   */
  readonly id: number;
}

/**
 * GovernmentInspectionApi - object-oriented interface
 * @export
 * @class GovernmentInspectionApi
 * @extends {BaseAPI}
 */
export class GovernmentInspectionApi extends BaseAPI {
  /**
   *
   * @summary List government inspection item results
   * @param {GovernmentInspectionApiGovernmentInspectionItemResultResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionItemResultResourceList(
    requestParameters: GovernmentInspectionApiGovernmentInspectionItemResultResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionItemResultResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.governmentInspectionWorkOrderId,
        requestParameters.itemId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a government inspection
   * @param {GovernmentInspectionApiGovernmentInspectionResourceCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceCreate(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceCreateRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceCreate(requestParameters.governmentInspectionCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a government inspection item
   * @param {GovernmentInspectionApiGovernmentInspectionResourceCreateItemRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceCreateItem(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceCreateItemRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceCreateItem(requestParameters.governmentInspectionSingleItemCreateView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a government inspection
   * @param {GovernmentInspectionApiGovernmentInspectionResourceDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceDelete(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Deletes a government inspection item
   * @param {GovernmentInspectionApiGovernmentInspectionResourceDeleteInspectionItemRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceDeleteInspectionItem(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceDeleteInspectionItemRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceDeleteInspectionItem(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export government inspections
   * @param {GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceExportExcelSummary(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceExportExcelSummary(requestParameters.token, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a token to export government inspection summary
   * @param {GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceExportExcelSummaryUrl(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceExportExcelSummaryUrlRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceExportExcelSummaryUrl(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.propertyAssigneeId,
        requestParameters.propertyId,
        requestParameters.category,
        requestParameters.search,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.propertyIds,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List government inspections
   * @param {GovernmentInspectionApiGovernmentInspectionResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceList(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.portfolioId,
        requestParameters.propertyAssigneeId,
        requestParameters.propertyId,
        requestParameters.category,
        requestParameters.search,
        requestParameters.organisationIds,
        requestParameters.portfolioIds,
        requestParameters.propertySubscriptionUserIds,
        requestParameters.municipalities,
        requestParameters.propertyIds,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a government inspection
   * @param {GovernmentInspectionApiGovernmentInspectionResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceRead(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a government inspection
   * @param {GovernmentInspectionApiGovernmentInspectionResourceUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceUpdate(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceUpdate(
        requestParameters.id,
        requestParameters.governmentInspectionUpdateView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a government inspection item
   * @param {GovernmentInspectionApiGovernmentInspectionResourceUpdateItemRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionResourceUpdateItem(
    requestParameters: GovernmentInspectionApiGovernmentInspectionResourceUpdateItemRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionResourceUpdateItem(
        requestParameters.id,
        requestParameters.governmentInspectionSingleItemUpdateView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a government inspection work order
   * @param {GovernmentInspectionApiGovernmentInspectionWorkOrderResourceCreateAndPerformRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionWorkOrderResourceCreateAndPerform(
    requestParameters: GovernmentInspectionApiGovernmentInspectionWorkOrderResourceCreateAndPerformRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionWorkOrderResourceCreateAndPerform(
        requestParameters.governmentInspectionWorkOrderCreateView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List government inspection work orders
   * @param {GovernmentInspectionApiGovernmentInspectionWorkOrderResourceListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionWorkOrderResourceList(
    requestParameters: GovernmentInspectionApiGovernmentInspectionWorkOrderResourceListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionWorkOrderResourceList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.organisationId,
        requestParameters.governmentInspectionId,
        requestParameters.faultReportWorkOrderId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Perform government inspection work order
   * @param {GovernmentInspectionApiGovernmentInspectionWorkOrderResourcePerformRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionWorkOrderResourcePerform(
    requestParameters: GovernmentInspectionApiGovernmentInspectionWorkOrderResourcePerformRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionWorkOrderResourcePerform(
        requestParameters.id,
        requestParameters.governmentInspectionWorkOrderPerformView,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Read a government inspection work order
   * @param {GovernmentInspectionApiGovernmentInspectionWorkOrderResourceReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GovernmentInspectionApi
   */
  public governmentInspectionWorkOrderResourceRead(
    requestParameters: GovernmentInspectionApiGovernmentInspectionWorkOrderResourceReadRequest,
    options?: AxiosRequestConfig
  ) {
    return GovernmentInspectionApiFp(this.configuration)
      .governmentInspectionWorkOrderResourceRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
