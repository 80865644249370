import React from 'react';
import { useTranslation } from 'react-i18next';
import { GovernmentInspectionItemReadView } from '../../../GeneratedServices';
import { Modal, Button } from 'semantic-ui-react';
import { governmentInspectionApi } from '../../../Http/Http';
import { globalStateCTX } from '../../../GlobalState/GlobalState';
import { toast } from 'react-toastify';

interface Props {
  item: GovernmentInspectionItemReadView;
  onClose: () => void;
  onSave: () => void;
}

enum Status {
  None,
  Deleting
}

const DeleteGovernmentInspectionItemModal: React.FC<Props> = (props) => {
  const { t } = useTranslation(['inspections', 'common']);
  const [status, setStatus] = React.useState<Status>(Status.None);
  const { handleHttpErrors } = React.useContext(globalStateCTX);

  const deleteInspectionItem = async () => {
    try {
      setStatus(Status.Deleting);

      await governmentInspectionApi.governmentInspectionResourceDeleteInspectionItem({
        id: props.item.id
      });
      toast.info(t('inspections:successfullyDeletedInspectionObject'));

      setStatus(Status.None);
    } catch (error) {
      handleHttpErrors(error);
    }
    props.onSave();
  };

  return (
    <Modal open onClose={props.onClose} closeIcon={status !== Status.Deleting} size="small">
      <Modal.Header>{`${t('inspections:deleteInspectionItem')} ${props.item.title}`}</Modal.Header>
      <Modal.Content>{t('inspections:areYouSureYouWantToDeleteTheInspectionObject')}</Modal.Content>
      <Modal.Actions>
        <Button primary onClick={deleteInspectionItem}>
          {t('common:yes')}
        </Button>
        <Button color="red" onClick={props.onClose}>
          {t('common:cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteGovernmentInspectionItemModal;
